<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Filtros" icon="pi pi-plus" class="p-button-success mr-2" @click="abrirFiltros" />
              <Button label="Desativar Implantação" icon="pi pi-trash" class="p-button-danger"
                @click="confirmDeleteSelected" :disabled="!selectImplantacoes || !selectImplantacoes.length" />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <!-- Tabela de Implantaçoes -->
        <DataTable ref="dt" :value="faturas" v-model:expandedRows="selectImplantacoes" dataKey="id" :paginator="true"
          :rows="20" :filters="filters" 
          :sortField="'extracao_id'" :sortOrder="-1"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gerenciar Implantações</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <!-- <i class="pi pi-search" />-->
                <InputText :disabled="false" v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column select style="width: 1rem" />
          <Column field="extracao_id" header="Extração" :sortable="true" headerStyle="width:3%; min-width:3rem;">
            <template #body="slotProps">
              <span class="p-column-title">Extração</span>
              {{ slotProps.data.extracao_id }}
            </template>
          </Column>
          <Column field="cliente" header="Empresa" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Empresa</span>
              {{ slotProps.data.cliente }}
            </template>
          </Column>
          <Column field="tensao" header="Tensão" :sortable="true" headerStyle="width:5%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Tensão</span>
              {{ slotProps.data.tensao }}
            </template>
          </Column>

          <Column field="unidade" header="Unidade" :sortable="true" headerStyle="width:10%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Unidade</span>
              {{ slotProps.data.unidade }}
            </template>
          </Column>

          <Column field="data_implantacao" header="Data de implantação" :sortable="true"
            headerStyle="width:10%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Data de implantação</span>
              <Tag severity="primary" 
                    :style="{ backgroundColor: '#4caf50',
                      width: '100%',
                      display: 'flex', 
                      justifyContent: 'center',
                      fontWeight: 'bolder',
                      fontSize: '0.875rem'
                    }"
                    :value="slotProps.data.data_implantacao">
            </Tag>
            </template>
          </Column>

          <Column field="mes_referencia" header="Mes Referencia" :sortable="true"
            headerStyle="width:10%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Mes Referencia</span>
              {{ slotProps.data.mes_referencia }}
            </template>
          </Column>
          

          <Column field="insumo" header="Insumo" :sortable="true" headerStyle="width:10%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Insumo</span>
              {{ slotProps.data.insumo }}
            </template>
          </Column>

          <Column field="concessionaria" header="Concessionaria" :sortable="true"
            headerStyle="width:14%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Concessionaria</span>
              {{ slotProps.data.concessionaria }}
            </template>
          </Column>

          <Column field="link_fatura" header="Fatura" :sortable="true" headerStyle="width:18%; min-width:8rem;">
              <template #body="slotProps">
                  <span class="p-column-title">Link fatura</span>

                  <Button
                    label="Acessar Fatura"
                  class="p-button-sm p-button-grey"
                  @click="abrirFatura(slotProps.data.link_fatura)"
                  />
              </template>
          </Column>

          <Column field="link_fatura" header="Fatura" :sortable="true" headerStyle="width:18%; min-width:8rem;">
              <template #body="slotProps">
                  <span class="p-column-title">Link fatura</span>

                  <Button
                    label="Ver Implantação"
                  class="p-button-sm p-button-grey"
                  @click="abrirModalEdicaoFatura(slotProps.data.id_fatura)"
                  />
              </template>
          </Column>



          
          <!-- <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editarFatura(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                @click="apagarFaturasModal(slotProps.data)" />
            </template>
          </Column> -->

        </DataTable>

        <Dialog v-model:visible="ModalFiltros" :style="{ width: '600px' }" header="Filtros" :modal="true"
          class="p-fluid">
          <div class="field">
            <label for="id_empresa">Empresa</label>
            <Dropdown id="id_empresa" filter required="true" v-model="filtros.id_empresa" :options="tipos_empresas"
              optionLabel="label" optionValue="value" placeholder="Selecione uma empresa"></Dropdown>
          </div>
          <div class="field">
            <label for="unidade">Unidade</label>
            <InputText :disabled="true" id="unidade" v-model.trim="filtros.unidade" required="true" autofocus />

          </div>
          <div class="field">
            <label for="mes_referencia">Mês Referencia</label>
            <Calendar id="mes_referencia" :disabled="true" view="month" dateFormat="mm/yy" v-model="filtros.mes_referencia" required="true" autofocus />

          </div>

          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="this.ModalFiltros = false" />
            <Button label="Pesquisar" icon="pi pi-check" class="p-button-success p-button-sm" @click="pesquisar()" />
          </template>
        </Dialog>

        <Dialog v-model:visible="ModalFatura" :style="{ width: '90%' }" header="Fatura" :modal="true"
          class="p-fluid">
          <div class="field">
            <label for="id_empresa">Empresa</label>
            <Dropdown id="id_empresa" filter required="true" v-model="filtros.id_empresa" :options="tipos_empresas"
              optionLabel="label" optionValue="value" placeholder="Selecione uma empresa"></Dropdown>
          </div>
          <div class="field">
            <label for="unidade">Unidade</label>
            <InputText :disabled="true" id="unidade" v-model.trim="filtros.unidade" required="true" autofocus />

          </div>
          <div class="field">
            <label for="mes_referencia">Mês Referencia</label>
            <Calendar id="mes_referencia"  :disabled="true" view="month" dateFormat="mm/yy" v-model="filtros.mes_referencia" required="true" autofocus />

          </div>

          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="this.ModalFatura = false" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="Salvar()" />
          </template>
        </Dialog>




        <!-- Modal deletar varias faturas -->
        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="fatura">Apagar Tipos de Concessionarias selecionados?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>


        <Dialog 
                    v-model:visible="modalFatura" 
                    header="Implantações"
                    :modal="true" 
                    class="p-fluid" 
                    style="width: 95%; height: 100%"
                >
                <div class="grid mt-4">
                </div>

                    <div class="grid mt-4">
                        <div class="col-6" style="position: sticky; top: 0; height: 100vh;">
                            <iframe 
                            :src="faturaImplantada.link_fatura + '#toolbar=0&navpanes=0'"
                            width="100%" 
                            height="100%"
                            type="application/pdf"
                            ></iframe>
                        </div>
                        
                        <div class="col-6">
                            <div class="grid ml-5 mt-2 gap-4">
                                
                                <div>
                                    <h2 class="text-xl font-medium">
                                        Dados do cliente
                                    </h2>

                                    <div class="col-12 grid bg-gray-100 p-4 mt-2 border-round">
                                        <div class="col-12 grid">
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="nome_empresa">Nome da empresa</label>
                                                    <InputText :disabled="true" id="nome_empresa" v-model.trim="faturaImplantada.dados_faturas.nome_empresa" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="cnpj">CNPJ</label>
                                                    <InputText :disabled="true" id="cnpj" v-model.trim="faturaImplantada.dados_faturas.cnpj" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="inscricao_estadual">Inscrição estadual</label>
                                                    <InputText :disabled="true" id="inscricao_estadual" v-model.trim="faturaImplantada.dados_faturas.inscricao_estadual" required="true"
                                                        autofocus />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="grid col-12">
                                            <div class="col-8">
                                                <div class="field">
                                                    <label for="endereco">Endereço</label>
                                                    <InputText :disabled="true" id="endereco" v-model.trim="faturaImplantada.dados_faturas.endereco" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="unidade_consumidora">Unidade consumidora</label>
                                                    <InputText :disabled="true" id="unidade_consumidora" v-model.trim="faturaImplantada.dados_faturas.unidade_consumidora" required="true"
                                                        autofocus />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h2 class="text-xl font-medium">
                                        Dados básicos
                                    </h2>

                                    <div class="col-12 grid bg-gray-100 p-4 mt-2 border-round">
                                        <div class="grid col-12">
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="concessionaria">Concessionária</label>
                                                    <InputText :disabled="true" 
                                                        id="concessionaria" 
                                                        v-model.trim="faturaImplantada.concessionaria.sigla" 
                                                        required="true"
                                                        autofocus 
                                                        readonly />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="nivel_tensao">Nível de tensão</label>
                                                    <InputText :disabled="true"  
                                                        id="nivel_tensao" 
                                                        v-model.trim="faturaImplantada.tipos_tensao_unidades.descricao" 
                                                        required="true"
                                                        autofocus 
                                                        readonly />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="tipo_insumo">Tipo de insumo</label>
                                                    <InputText :disabled="true" 
                                                        id="tipo_insumo" 
                                                        v-model.trim="faturaImplantada.tipos_insumos_unidades.descricao" 
                                                        required="true"
                                                        autofocus 
                                                        readonly />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="grid col-12">
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="total_fatura">Total da fatura</label>
                                                    <InputText :disabled="true" 
                                                        id="total_fatura"
                                                        v-model="faturaImplantada.dados_faturas.total_fatura"
                                                        required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="conta_contrato">Conta contrato</label>
                                                    <InputText :disabled="true" id="conta_contrato" v-model.trim="faturaImplantada.dados_faturas.conta_contrato" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="tipo_ligacao">Tipo de ligação</label>
                                                    <Dropdown
                                                        :options="tipos_ligacao"
                                                        v-model="faturaImplantada.id_tipo_ligacao"
                                                        optionValue="id"
                                                        optionLabel="descricao"
                                                        placeholder="Selecione um tipo de ligação"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 grid">
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="mes_referencia">Mês referência</label>
                                                    <Calendar 
                                                        :disabled="true"
                                                        v-model="faturaImplantada.mes_referencia" 
                                                        id="mes_referencia" 
                                                        dateFormat="yy-mm-dd" 
                                                        placeholder="Selecione a data"
                                                        class="gap-1" 
                                                        showIcon 
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="data_vencimento">Data de vencimento</label>
                                                    <Calendar 
                                                        :disabled="true"
                                                        v-model="faturaImplantada.data_vencimento" 
                                                        id="data_vencimento" 
                                                        dateFormat="yy-mm-dd" 
                                                        placeholder="Selecione a data"
                                                        class="gap-1" 
                                                        showIcon 
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="data_emissao">Data de emissão</label>
                                                    <Calendar 
                                                        :disabled="true"
                                                        v-model="faturaImplantada.data_emissao_fatura" 
                                                        id="data_emissao" 
                                                        dateFormat="yy-mm-dd" 
                                                        placeholder="Selecione a data"
                                                        class="gap-1" 
                                                        showIcon 
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="grid col-12">
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="classe">Classe</label>
                                                    <Dropdown
                                                        :filter="true"
                                                        :options="tiposClassesUnidades"
                                                        v-model="faturaImplantada.id_classe"
                                                        optionValue="id"
                                                        optionLabel="descricao"
                                                        placeholder="Selecione um item normalizado para classe"
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="subclasse">Subclasse</label>
                                                    <Dropdown
                                                        :options="tiposSubClassesUnidades"
                                                        v-model="faturaImplantada.id_subclasse"
                                                        :filter="true"
                                                        optionValue="id"
                                                        optionLabel="descricao"
                                                        placeholder="Selecione um item normalizado para subclasse"
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field"> 
                                                    <label for="modalidade">Modalidade</label>
                                                    <Dropdown
                                                        :options="tiposModalidadesUnidades"
                                                        v-model="faturaImplantada.id_modalidade"
                                                        :filter="true"
                                                        optionValue="id"
                                                        optionLabel="descricao"
                                                        placeholder="Selecione um item normalizado para modalidade"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="grid col-12">
                                            <div class="col-12">
                                                <div class="field">
                                                    <label for="informacoes">Informações</label>
                                                    <Textarea 
                                                        :disabled="true"
                                                        id="informacoes" 
                                                        v-model.trim="faturaImplantada.dados_faturas.informacoes" 
                                                        required="true"
                                                        rows="5" 
                                                        cols="30" 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h2 class="text-xl font-medium">
                                        Dados de leitura
                                    </h2>

                                    <div class="col-12 grid bg-gray-100 p-4 mt-2 border-round">
                                        <div class="col-12 grid">
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="data_leitura_anterior">Data de leitura anterior</label>
                                                    <Calendar 
                                                        :disabled="true"
                                                        v-model="faturaImplantada.dados_faturas.data_leitura_anterior" 
                                                        id="data_leitura_anterior" 
                                                        dateFormat="yy-mm-dd" 
                                                        placeholder="Selecione a data"
                                                        class="gap-1" 
                                                        showIcon 
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="data_leitura_atual">Data de leitura atual</label>
                                                    <Calendar 
                                                        :disabled="true"
                                                        v-model="faturaImplantada.dados_faturas.data_leitura_atual" 
                                                        id="data_leitura_atual" 
                                                        dateFormat="yy-mm-dd" 
                                                        placeholder="Selecione a data"
                                                        class="gap-1" 
                                                        showIcon 
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="data_proxima_leitura">Data da próxima leitura</label>
                                                    <Calendar 
                                                        :disabled="true"
                                                        v-model="faturaImplantada.dados_faturas.data_proxima_leitura" 
                                                        id="data_emissao" 
                                                        dateFormat="yy-mm-dd" 
                                                        placeholder="Selecione a data"
                                                        class="gap-1" 
                                                        showIcon 
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="grid col-12">
                                            <div class="col-3">
                                                <div class="field">
                                                    <label for="dias_leitura">Dias de leitura</label>
                                                    <InputText :disabled="true" id="dias_leitura" v-model.trim="faturaImplantada.dados_faturas.dias_fatura" required="true"
                                                        autofocus />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- Start Dados Fiscais Field -->
                                <div>
                                    <h2 class="text-xl font-medium">Dados Fiscais</h2>

                                    <div class="col-12 grid bg-gray-100 p-4 mt-2 border-round">
                                        <!-- Start Columns Dados Fiscais Field -->
                                        <div class="col-12 grid">
                                            
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="nota_fiscal">Nota Fiscal</label>
                                                    <InputText :disabled="true" id="nota_fiscal" v-model.trim="faturaImplantada.dados_fiscais_faturas.nota_fiscal" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-2">
                                                <div class="field">
                                                    <label for="serie_nota_fiscal">Serie nota fiscal</label>
                                                    <InputText :disabled="true" id="serie_nota_fiscal" v-model.trim="faturaImplantada.dados_fiscais_faturas.serie_nota_fiscal" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-6">
                                                <div class="field">
                                                    <label for="fisco">Fisco</label>
                                                    <InputText :disabled="true" id="fisco" v-model.trim="faturaImplantada.dados_fiscais_faturas.fisco" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-3">
                                                <div class="field">
                                                    <label for="qr_code_chave_acesso">Chave de acesso</label>
                                                    <InputText :disabled="true" id="qr_code_chave_acesso" v-model.trim="faturaImplantada.dados_fiscais_faturas.qr_code_chave_acesso" required="true"
                                                        autofocus />
                                                </div>
                                            </div>


                                            <div class="col-3">
                                                <div class="field">
                                                    <label for="qr_code_protocolo">Protocolo</label>
                                                    <InputText :disabled="true" id="qr_code_protocolo" v-model.trim="faturaImplantada.dados_fiscais_faturas.qr_code_protocolo" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-6">
                                                <div class="field">
                                                    <label for="codigo_de_barras">Código de barras</label>
                                                    <InputText :disabled="true" id="codigo_de_barras" v-model.trim="faturaImplantada.dados_fiscais_faturas.codigo_de_barras" required="true"
                                                        autofocus />
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End Columns Dados Fiscais Field -->
                                    </div>
                                </div>
                                <!-- End Dados Fiscais Field -->


                                <div class="col-12">
                                    <DataTable
                                            ref="dt"
                                            :value="faturaImplantada.dados_itens_faturados"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="5"
                                            :filters="filters"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            :rowsPerPageOptions="[5, 10, 25]"
                                            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                            responsiveLayout="scroll"
                                            style="table-layout: auto; white-space: nowrap;"
                                        >
                                            <template #header>
                                                <div class="grid align-items-center">
                                                    <div class="col-12">
                                                        <h5>Itens Faturados</h5>
                                                    </div>
                                                    
                                                    <!-- <div class="col-5">
                                                        <Button 
                                                            label="Novo item faturado" 
                                                            icon="pi pi-plus" 
                                                            class="p-button-success" 
                                                            @click="abrirModalAdicaoItemFaturado" />
                                                    </div> -->

                                                    <div class="col-7">
                                                        <span class="p-input-icon-left">
                                                            <!-- <i class="pi pi-search" />-->
                                                           <!-- <InputText :disabled="true" v-model="filters['global'].value" placeholder="Buscar" /> -->
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>
                                            <Column field="cci" header="CCI"></Column>
                                            <Column field="descricao_normalizada.descricao" header="Descrição"></Column>
                                            <Column field="unidade_medida" header="Unidade de medida"></Column>
                                            <Column field="quantidade" header="Quantidade"></Column>
                                            <Column field="tarifa_com_tributos" header="Tarifa c/ tributos"></Column>
                                            <Column field="tarifa_sem_tributos" header="Tarifa s/ tributos"></Column>
                                            <Column field="valor" header="Valor"></Column>
                                            <Column field="aliquota_icms" header="Alíquota ICMS"></Column>
                                            <Column field="valor_icms" header="Valor ICMS"></Column>
                                            <Column field="valor_pis" header="Valor PIS"></Column>
                                            <Column field="valor_cofins" header="Valor COFINS"></Column>
                                            <Column field="valor_pis_cofins" header="Valor PIS/COFINS"></Column>
                                            <Column field="valor_base_calculo_icms" header="Valor base calc. ICMS"></Column>
                                            <Column field="valor_base_calculo_pis_cofins" header="Valor base calc. PIS/COFINS"></Column>
                                    
                                            <!-- <Column header="">
                                                <template #body="slotProps">
                                                <Button 
                                                    icon="pi pi-pencil" 
                                                    class="p-button-rounded p-button-success mr-2" 
                                                    @click="abrirModalEdicaoItemFaturado(slotProps.data)"
                                                />
                                                <Button 
                                                    icon="pi pi-trash" 
                                                    class="p-button-rounded p-button-warning mt-2" 
                                                    @click="deleteItemFaturado(slotProps.data.id)"
                                                />
                                                </template>
                                            </Column> -->
                                    </DataTable>
                                </div>

                                <!-- Start Media tensão block -->
                                <div v-if="faturaImplantada.id_tensao != 1" style="width: 100%;">

                                    <!-- Start Demanda contratada block -->
                                    <div class="col-12">
                                        <DataTable
                                            ref="dt"
                                            :value="faturaImplantada.dados_demanda_contratada_faturas"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="5"
                                            :filters="filters"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            :rowsPerPageOptions="[5, 10, 25]"
                                            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                            responsiveLayout="scroll"
                                            style="table-layout: auto; white-space: nowrap;"
                                            
                                            >
                                            <template #header>
                                                <div class="grid align-items-center">
                                                    <div class="col-12">
                                                        <h5>Demandas contratadas</h5>
                                                    </div>
                                                    <div class="col-5">
                                                        <Button label="Nova Demanda Contratada" icon="pi pi-plus" class="p-button-success" @click="adicionarLinha('dados_demanda_contratada_faturas')" />
                                                    </div>
                                                    <div class="col-7">
                                                        <span class="p-input-icon-left">
                                                            <!-- <i class="pi pi-search" />-->
                                                           <!-- <InputText :disabled="true" v-model="filters['global'].value" placeholder="Buscar" /> -->
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>
                                            <Column field="descricao" header="Descricao">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="quantidade" header="Quantidade">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                    

                                            <!-- <Column header="">
                                                <template #body="slotProps">
                                                    <Button 
                                                        icon="pi pi-trash" 
                                                        class="p-button-rounded p-button-danger mt-2" 
                                                        @click="deletarLinha(slotProps.data, 'dados_demanda_contratada_faturas')"
                                                    />
                                                </template>
                                            </Column> -->
                                        </DataTable>
                                    </div>
                                    <!-- End Demanda contratada block -->


                                    <!-- Start Niveis Tensão Faturas block -->
                                    <div class="col-12">
                                        <DataTable
                                            ref="dt"
                                            :value="faturaImplantada.dados_niveis_tensao_faturas"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="5"
                                            :filters="filters"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            :rowsPerPageOptions="[5, 10, 25]"
                                            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                            responsiveLayout="scroll"
                                            style="table-layout: auto; white-space: nowrap;"
                                            
                                            >
                                            <template #header>
                                                <div class="grid align-items-center">
                                                    <div class="col-12">
                                                        <h5>Níveis Tensão Fatura</h5>
                                                    </div>
                                                    <div class="col-5">
                                                        <Button label="Adicionar Niveis Tensão" icon="pi pi-plus" class="p-button-success" @click="adicionarLinha('dados_niveis_tensao_faturas')" />
                                                    </div>
                                                    <div class="col-7">
                                                        <span class="p-input-icon-left">
                                                            <!-- <i class="pi pi-search" />-->
                                                           <!-- <InputText :disabled="true" v-model="filters['global'].value" placeholder="Buscar" /> -->
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>

                                            <Column field="descricao" header="Descricao">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="quantidade" header="Quantidade">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                    

                                            <!-- <Column header="">
                                                <template #body="slotProps">
                                                    <Button 
                                                        icon="pi pi-trash"  
                                                        class="p-button-rounded p-button-danger mt-2" 
                                                        @click="deletarLinha(slotProps.data, 'dados_niveis_tensao_faturas')"
                                                    />
                                                </template>
                                            </Column> -->
                                        </DataTable>
                                    </div>
                                    <!-- End Niveis Tensão Faturas block -->


                                    <!-- Start Leituras Faturas block -->
                                    <div class="col-12">
                                        <DataTable
                                            ref="dt"
                                            :value="faturaImplantada.dados_leituras_faturas"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="5"
                                            :filters="filters"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            :rowsPerPageOptions="[5, 10, 25]"
                                            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                            responsiveLayout="scroll"
                                            style="table-layout: auto; white-space: nowrap;"
                                            
                                            >
                                            <template #header>
                                                <div class="grid align-items-center">
                                                    <div class="col-12">
                                                        <h5>Leituras Fatura Média Tensão</h5>
                                                    </div>
                                                    <div class="col-5">
                                                        <Button label="Adicionar Leitura para Média Tensão" icon="pi pi-plus" class="p-button-success" @click="adicionarLinha('dados_leituras_faturas')" />
                                                    </div>
                                                    <div class="col-7">
                                                        <span class="p-input-icon-left">
                                                            <!-- <i class="pi pi-search" />-->
                                                           <!-- <InputText :disabled="true" v-model="filters['global'].value" placeholder="Buscar" /> -->
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>

                                            <Column field="unidade_medida" header="Unidade Medida">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="posto_horario" header="Posto Horário">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="leitura_atual" header="Leitura Atual">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="leitura_anterior" header="Leitura Anterior">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="fator_multiplicador" header="Fator Multiplicador">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="perdas" header="Perdas">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="fator_potencia" header="Fator Potencia">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="ajuste_fator_potencia" header="Ajuste Fator Potencia">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                    
                                            <Column field="consumo_medido" header="Consumo medido">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                    
                                            <Column field="consumo_faturado" header="Consumo Faturado">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                    
                                            <Column field="consumo_contratado" header="Consumo Contratado">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="demanda_medida" header="Demanda Medida">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="demanda_contratada" header="Demanda Contratada">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                    
                                            <Column field="dmcr_medida" header="DMCR Medida">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="ufer_medida" header="Ufer Medida">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="kva_medido" header="KVA Medido">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="reservado_consumo" header="Reservado consumo">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="energia_injetada_fora_ponta" header="Energia Inj. Fora Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="energia_injetada_ponta" header="Energia Inj. Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="energia_injetada_reservado" header="Energia Inj. Reservado">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="demanda_fora_ponta" header="Demanda Fora Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="demanda_ponta" header="Demanda Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="ufer_fora_ponta" header="Ufer Fora Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="ufer_ponta" header="Ufer Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="ufer_reservado" header="Ufer Reservado">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="dmcr_fora_ponta" header="DMCR Fora Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="dmcr_ponta" header="DMCR Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="tarifa_com_tributos" header="Tarifas com tributos">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="total" header="Total">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                    

                                            <!-- <Column header="">
                                                <template #body="slotProps">
                                                    <Button 
                                                        icon="pi pi-trash"  
                                                        class="p-button-rounded p-button-danger mt-2" 
                                                        @click="deletarLinha(slotProps.data, 'dados_leituras_faturas')"
                                                    />
                                                </template>
                                            </Column> -->
                                        </DataTable>
                                    </div>
                                    <!-- End Leituras Faturas block -->


                                </div>
                                <!-- End Media tensão block -->
                                    <div class="col-12">
                                        <DataTable
                                            ref="dt"
                                            :value="faturaImplantada.dados_medidor_faturas"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="5"
                                            :filters="filters"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            :rowsPerPageOptions="[5, 10, 25]"
                                            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                            responsiveLayout="scroll"
                                            style="table-layout: auto; white-space: nowrap;"
                                            
                                            
                                        >
                                            <template #header>
                                                <div class="grid align-items-center">
                                                    <div class="col-12">
                                                        <h5>Dados medidores & leituras</h5>
                                                    </div>
                                                    <!-- <div class="col-5">
                                                        <Button label="Novo item medidor" icon="pi pi-plus" class="p-button-success" @click="adicionarLinha('dados_medidor_faturas')" />
                                                    </div> -->
                                                    <div class="col-7">
                                                        <span class="p-input-icon-left">
                                                            <!-- <i class="pi pi-search" />-->
                                                           <!-- <InputText :disabled="true" v-model="filters['global'].value" placeholder="Buscar" /> -->
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>

                                            <Column field="id" header="ID"></Column>
                                            <Column field="id_fatura" header="Id Fatura"></Column>
                                            <Column field="medidor" header="Medidor">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="grandeza" header="Grandeza">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="posto_horario" header="Posto Horário">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="constante_medidor" header="Constante">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="leitura_anterior" header="Leitura Anterior">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="leitura_atual" header="Leitura Atual">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="consumo_medidor" header="Consumo Medidor">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            

                                            <!-- <Column header="">
                                                <template #body="slotProps">
                                                    <Button 
                                                        icon="pi pi-trash" 
                                                        class="p-button-rounded p-button-danger mt-2" 
                                                        @click="deletarLinha(slotProps.data, 'dados_medidor_faturas')"
                                                    />
                                                </template>
                                            </Column> -->
                                        </DataTable>
                                    </div>
                                    <!-- End Niveis Tensão Faturas block -->

                                    <div class="col-12">
                                        <DataTable
                                            ref="dt"
                                            :value="faturaImplantada.dados_tributos_faturas"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="5"
                                            :filters="filters"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            :rowsPerPageOptions="[5, 10, 25]"
                                            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                            responsiveLayout="scroll"
                                            style="table-layout: auto; white-space: nowrap;"
                                            
                                            
                                        >
                                            <template #header>
                                                <div class="grid align-items-center">
                                                    <div class="col-12">
                                                        <h5>Dados tributos fatura</h5>
                                                    </div>
                                                    <!-- <div class="col-5">
                                                        <Button label="Novo item tributos" icon="pi pi-plus" class="p-button-success" @click="adicionarLinha('dados_tributos_faturas')" />
                                                    </div> -->
                                                    <div class="col-7">
                                                        <span class="p-input-icon-left">
                                                            <!-- <i class="pi pi-search" />-->
                                                           <!-- <InputText :disabled="true" v-model="filters['global'].value" placeholder="Buscar" /> -->
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>

                                            <Column field="id" header="ID"></Column>
                                            <Column field="id_fatura" header="Id Fatura"></Column>
                                            <Column field="descricao" header="descricao">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="aliquota" header="Aliquota">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="base_calculo" header="Base Calculo">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="valor" header="Valor Reais">
                                                <template #editor="{ data, field }">
                                                    <InputText :disabled="true" v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <!-- <Column header="">
                                                <template #body="slotProps">
                                                    <Button 
                                                        icon="pi pi-trash" 
                                                        class="p-button-rounded p-button-danger mt-2" 
                                                        @click="deletarLinha(slotProps.data, 'dados_tributos_faturas')"
                                                    />
                                                </template>
                                            </Column> -->
                                        </DataTable>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <template #footer>
                        <Button label="Fechar" icon="pi pi-times" class="p-button-secondary p-button-sm"
                            @click="this.modalFatura = false" />
                        <!-- <Button label="Salvar fatura" icon="pi pi-check" class="p-button-success p-button-sm" :loading="loading"
                            @click="salvarFatura" /> -->
                    </template>
                </Dialog>

                <Dialog 
                    v-model:visible="modalItemFaturado" 
                    header="Implantações"
                    :modal="true" 
                    class="p-fluid" 
                    style="width: 95%; height: 100%"
                >
                    <div class="grid">
                        <div class="col-6" style="position: sticky; top: 0; height: 100%;">
                            <iframe 
                                :src="faturaImplantada.link_fatura"
                                width="100%" height="500px"
                                type="application/pdf"
                            ></iframe>
                        </div>
                        
                        <div class="col-6">
                            <div class="grid ml-5 mt-2">
                                <div class="grid col-12">
                                    <div class="field col-2">
                                        <label for="cci-item-faturado">CCI</label>
                                        <InputText :disabled="true" 
                                            id="cci-item-faturado" 
                                            v-model.trim="itemFaturado.cci" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                    
                                    <div class="field col-4">
                                        <label for="descricao-item-faturado">Descrição</label>
                                        <Dropdown
                                            :options="tiposItensNormalizados"
                                            v-model="itemFaturado.id_classificacao"
                                            optionValue="id"
                                            optionLabel="descricao"
                                            placeholder="Selecione um item normalizado para modalidade"
                                        />
                                    </div>

                                    <div class="field col-3">
                                        <label for="quantidade-item-faturado">Quantidade</label>
                                        <InputText :disabled="true" 
                                            id="quantidade-item-faturado" 
                                            v-model.trim="itemFaturado.quantidade" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>

                                    <div class="field col-3">
                                        <label for="unidade-medida-item-faturado">Unidade de medida</label>
                                        <InputText :disabled="true" 
                                            id="unidade-medida-item-faturado" 
                                            v-model.trim="itemFaturado.unidade_medida" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                </div>

                                <div class="grid col-12">
                                    <div class="field col-3">
                                        <label for="tarifa-com-tributos-item-faturado">Tarifa c/ tributos</label>
                                        <InputText :disabled="true" 
                                            id="tarifa-com-tributos-item-faturado" 
                                            v-model.trim="itemFaturado.tarifa_com_tributos" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                    
                                    <div class="field col-3">
                                        <label for="tarifa-sem-tributos-item-faturado">Tarifa s/ tributos</label>
                                        <InputText :disabled="true" 
                                            id="tarifa-sem-tributos-item-faturado" 
                                            v-model.trim="itemFaturado.tarifa_sem_tributos" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>

                                    <div class="field col-3">
                                        <label for="valor-item-faturado">Valor</label>
                                        <InputText :disabled="true" 
                                            id="valor-item-faturado" 
                                            v-model.trim="itemFaturado.valor" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>

                                    <div class="field col-3">
                                        <label for="aliquota-icms-item-faturado">Alíquota ICMS</label>
                                        <InputText :disabled="true" 
                                            id="aliquota-icms-item-faturado" 
                                            v-model.trim="itemFaturado.aliquota_icms" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                </div>

                                <div class="grid col-12">
                                    <div class="field col-3">
                                        <label for="valor-icms-item-faturado">Valor ICMS</label>
                                        <InputText :disabled="true" 
                                            id="valor-icms-item-faturado" 
                                            v-model.trim="itemFaturado.valor_icms" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                    
                                    <div class="field col-3">
                                        <label for="valor-pis-item-faturado">Valor PIS</label>
                                        <InputText :disabled="true" 
                                            id="valor-pis-item-faturado" 
                                            v-model.trim="itemFaturado.valor_pis" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>

                                    <div class="field col-3">
                                        <label for="valor-cofins-item-faturado">Valor COFINS</label>
                                        <InputText :disabled="true" 
                                            id="valor-cofins-item-faturado" 
                                            v-model.trim="itemFaturado.valor_cofins" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>

                                    <div class="field col-3">
                                        <label for="valor-pis-cofins-item-faturado">Valor PIS/COFINS</label>
                                        <InputText :disabled="true" 
                                            id="valor-pis-cofins-item-faturado" 
                                            v-model.trim="itemFaturado.valor_pis_cofins" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                </div>

                                <div class="grid col-12">
                                    <div class="field col-3">
                                        <label for="valor-base-calc-icms-item-faturado">Valor base calc. ICMS</label>
                                        <InputText :disabled="true" 
                                            id="valor-base-calc-icms-item-faturado" 
                                            v-model.trim="itemFaturado.valor_base_calculo_icms" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                    
                                    <div class="field col-3">
                                        <label for="valor-base-calc-pis_cofins-item-faturado">Valor base calc. PIS/COFINS</label>
                                        <InputText :disabled="true" 
                                            id="valor-base-calc-pis_cofins-item-faturado" 
                                            v-model.trim="itemFaturado.valor_base_calculo_pis_cofins" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
                            @click="this.modalItemFaturado = false" />
                        <Button label="Salvar Item Faturado" icon="pi pi-check" class="p-button-success p-button-sm"
                            @click="salvarItemFaturado" />
                    </template>
                </Dialog>



      </div>
    </div>
  </div>
</template>

<script>

import { FilterMatchMode } from "primevue/api";
import funcoes from '../../funcoes.js';

export default {
  mixins: [funcoes],
  data() {
    return {
      filtros: [],
      ModalFiltros: false,
      faturas: [],
      fatura: {},
      selectImplantacoes: null,
      ModalFatura: false,

      medidor: {},
      modalEditMedidor: false,
      medidorDialog: false,

      itens_faturados: {},
      modalEditItens: false,

      dados_faturas: {},
      modalEditDados: false,

      dados_fiscais: {},
      modalEditDadosFiscais: false,

      dados_tributos: {},
      modalEditDadosTributos: false,

      deleteModalFatura: false,
      deleteMedidorDialog: false,
      deleteDadosDialog: false,
      deleteDadosFiscaisDialog: false,
      deleteDadosTributosDialog: false,
      deleteItensFaturadosDialog: false,
      deleteProductsDialog: false,

      filters: {},
      submitted: false,

      tipos_empresas: [],
      desc_tipos_empresas: [],
      tipo_insumo: [],
      desc_tipo_insumo: [],
      tipos_modalidades: [],
      tipos_concess: [],
      tipos_pagamentos: [],
      tipos_classe_tarifaria: [],
      tipos_subclasse: [],
      tipos_tensao: [],
      tipos_ligacao: [],
      tiposItensNormalizados: [],
      tiposClassesUnidades: [],
      tiposSubClassesUnidades: [],
      tiposModalidadesUnidades: [],


      faturaImplantada: {},
      modalFatura: false,

    };
  },

  created() {
    this.initFilters();
  },
  mounted() {
    this.listarFaturasImplantadas();
        this.listarTiposLigacao();
        this.listarItensNormalizados();
        this.listarClassesUnidades();
        this.listarSubClassesUnidades();
        this.listarModalidadesUnidades();
  },


  methods: {

    abrirModalEdicaoFatura(id_fatura) {
      this.axios
          .post("/faturas/listar-fatura", {'id_fatura': id_fatura})
          .then((response) => {
              this.faturaImplantada = response.data.dados;
              this.faturaImplantada.link_fatura = this.faturaImplantada.link_fatura.replace('.json', '');
              this.modalFatura = true;
          })
          .catch(() => {
              this.$toast.add({
                  severity: "error",
                  summary: "Ops!",
                  detail: 'Chamar a T.I',
                  life: 2500,
              });
          });
    },

    listarFaturasImplantadas(){
      this.axios
        .get("/faturas/listar-implantacoes")
        .then((response) => {
          this.faturas = response.data.dados;
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },

    /** 
     * listarTiposLigacao - list all tipos ligações
    */
    listarTiposLigacao() {
        this.axios
            .post('/unidades/dados/tipos-ligacao/listar')
            .then((response) => {
                this.tipos_ligacao = response.data.dados;
            }).catch(() => {
                this.$toast.add({
                    severity: "error",
                    summary: "Ops!",
                    detail: 'Chamar a T.I',
                    life: 2500,
                });
        });
    },

      /** 
     * listarItensNormalizados - list all itens normalizados
    */
    listarItensNormalizados() {
        this.axios
            .post('/dicionario/normalizados')
            .then((response) => {
                this.tiposItensNormalizados = response.data.dados;
            }).catch(() => {
                this.$toast.add({
                    severity: "error",
                    summary: "Ops!",
                    detail: 'Chamar a T.I',
                    life: 2500,
                });
        });
    },

    /** 
     * listarClassesUnidades - list all classes registered
     */
      listarClassesUnidades() {
        this.axios
            .post('/unidades/dados/tipos-classe-tarifaria/listar')
            .then((response) => {
                this.tiposClassesUnidades = response.data.dados;
            }).catch(() => {
                this.$toast.add({
                    severity: "error",
                    summary: "Ops!",
                    detail: 'Chamar a T.I',
                    life: 2500,
                });
        });
    },

    /** 
    * listarSubClassesUnidades - list all Sub classes registered
    */
    listarSubClassesUnidades() {
    this.axios
        .post('/unidades/dados/tipos-sub-classe/listar')
        .then((response) => {
            this.tiposSubClassesUnidades = response.data.dados;
        }).catch(() => {
            this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: 'Chamar a T.I',
                life: 2500,
            });
    });
    },

    /** 
    * listarModalidadesUnidades - list all modalidades registered
    */
    listarModalidadesUnidades() {
        this.axios
            .post('/unidades/dados/tipos-modalidade-tarifaria/listar')
            .then((response) => {
                this.tiposModalidadesUnidades = response.data.dados;
            }).catch(() => {
                this.$toast.add({
                    severity: "error",
                    summary: "Ops!",
                    detail: 'Chamar a T.I',
                    life: 2500,
                });
        });
    },

    abrirFatura(link) {
      if (!link) {
          this.$toast.add({ severity: 'warn', summary: 'Erro', detail: 'Fatura não encontrada', life: 3000 });
          return;
      }

      window.open(link, '_blank');
    },

    openNew() {
      this.fatura = {};
      this.submitted = false;
      this.ModalFatura = true;
    },

    abrirFiltros() {
      this.ModalFiltros = true;
    },

    editarFatura(fatura) {
      this.ModalFatura = true;
      this.fatura = { ...fatura };
    },

    editDadosMedidor(medidor) {
      this.modalEditMedidor = true;
      this.medidor = { ...medidor };
    },

    editDadosFiscais(dados_fiscais) {
      this.modalEditDadosFiscais = true;
      this.dados_fiscais = { ...dados_fiscais };
    },

    editDadosTributos(dados_tributos) {
      this.modalEditDadosTributos = true;
      this.dados_tributos = { ...dados_tributos };
    },

    editItensFaturados(itens_faturados) {
      this.modalEditItens = true;
      this.itens_faturados = { ...itens_faturados };
    },

    editDadosFatura(dados_faturas) {
      this.modalEditDados = true;
      this.dados_faturas = { ...dados_faturas };
    },

    hideDialog() {
      this.ModalFatura = false;
      this.submitted = false;
    },

    addDadosMedidor(medidor) {
      this.modalEditMedidor = true;
      this.medidor.id_fatura = medidor.id;
    },

    addItensFaturados(itens_faturados) {
      this.modalEditItens = true;
      this.itens_faturados.id_fatura = itens_faturados.id;
    },




    // criar/editar fatura
    salvarFatura() {
      this.submitted = true;

      if (this.faturaImplantada.id) {
        // Ediçao de fatura
        this.axios
          .post("/faturas/editar", this.fatura)
          .then(() => {
            this.ModalFatura = false;
            this.fatura = {};
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar nova fatura
        this.axios
          .post("/faturas/adicionar", this.fatura)
          .then(() => {

            // alert(response.data.data)

            this.ModalFatura = false;
            this.fatura = {};
            this.listarFaturas();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },

    salvarMedidorFatura() {
      this.submitted = true;

      if (this.medidor.id) {
        this.axios
          .post("/faturas/medidor/editar", this.medidor)
          .then(() => {
            this.modalEditMedidor = false;
            this.medidor = {};
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar nova medidor
        this.axios
          .post("/faturas/medidor/adicionar", this.medidor)
          .then(() => {

            // alert(response.data.data)

            this.modalEditMedidor = false;
            this.medidor = {};
            this.listarFaturas();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },

    salvarItensFaturadosFatura() {
      this.submitted = true;

      if (this.itens_faturados.id) {
        // Ediçao de itens
        this.axios
          .post("/faturas/itens-faturados/editar", this.itens_faturados)
          .then(() => {


            this.modalEditItens = false;
            this.itens_faturados = {};
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar nova itens_faturados
        this.axios
          .post("/faturas/itens-faturados/adicionar", this.itens_faturados)
          .then(() => {

            // alert(response.data.data)

            this.modalEditItens = false;
            this.itens_faturados = {};
            this.listarFaturas();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },

    salvarDadosFatura() {
      this.submitted = true;

      if (this.dados_faturas.id) {
        // Ediçao de itens
        this.axios
          .post("/faturas/dados/editar", this.dados_faturas)
          .then(() => {


            this.modalEditDados = false;
            this.dados_faturas = {};
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar nova dados_faturas
        this.axios
          .post("/faturas/dados/adicionar", this.dados_faturas)
          .then(() => {

            // alert(response.data.data)

            this.modalEditDados = false;
            this.dados_faturas = {};
            this.listarFaturas();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },

    salvarDadosFiscaisFatura() {
      this.submitted = true;

      if (this.dados_fiscais.id) {
        // Ediçao de itens
        this.axios
          .post("/faturas/dados-fiscais/editar", this.dados_fiscais)
          .then(() => {


            this.modalEditDados = false;
            this.dados_fiscais = {};
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar nova dados_fiscais
        this.axios
          .post("/faturas/dados-fiscais/adicionar", this.dados_fiscais)
          .then(() => {

            // alert(response.data.data)

            this.modalEditDados = false;
            this.dados_fiscais = {};
            this.listarFaturas();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },

    salvarDadosTributosFatura() {
      this.submitted = true;

      if (this.dados_tributos.id) {
        // Ediçao de itens
        this.axios
          .post("/faturas/dados-tributos/editar", this.dados_tributos)
          .then(() => {


            this.modalEditDadosTributos = false;
            this.dados_tributos = {};
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar nova dados_tributos
        this.axios
          .post("/faturas/dados-tributos/adicionar", this.dados_tributos)
          .then(() => {

            // alert(response.data.data)

            this.modalEditDadosTributos = false;
            this.dados_tributos = {};
            this.listarFaturas();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },







    apagarFaturasModal(fatura) {
      this.fatura = fatura;
      this.deleteModalFatura = true;
    },

    apagarDadosMedidorModal(medidor_faturas) {
      this.medidor_faturas = medidor_faturas;
      this.deleteMedidorDialog = true;
    },

    apagarDadosFiscaisModal(dados_fiscais) {
      this.dados_fiscais = dados_fiscais;
      this.deleteDadosFiscaisDialog = true;
    },

    apagarDadosTributosModal(dados_tributos) {
      this.dados_tributos = dados_tributos;
      this.deleteDadosTributosDialog = true;
    },

    apagarDadosFaturaModal(dados_faturas) {
      this.dados_faturas = dados_faturas;
      this.deleteDadosDialog = true;
    },

    apagarItensFaturadosModal(itens_faturados) {
      this.itens_faturados = itens_faturados;
      this.deleteItensFaturadosDialog = true;
    },

    // botao de excluir selecionados
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },

    apagarFaturas() {
      this.axios
        .post("/faturas/apagar", this.fatura)
        .then(() => {

          this.deleteModalFatura = false;
          this.fatura = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Fatura apagada com sucesso!",
            life: 3000,
          });

          this.listarFaturas();


        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

    apagarItensFaturados() {
      this.axios
        .post("/faturas/itens-faturados/apagar", this.itens_faturados)
        .then(() => {

          this.deleteItensFaturadosDialog = false;
          this.itens_faturados = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Item apagado com sucesso!",
            life: 3000,
          });

          this.listarFaturas();


        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

    apagarDadosMedidor() {
      this.axios
        .post("/faturas/medidor/apagar", this.medidor_faturas)
        .then(() => {

          this.deleteMedidorDialog = false;
          this.medidor_faturas = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Medidor apagado com sucesso!",
            life: 3000,
          });

          this.listarFaturas();


        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

    apagarDados() {
      this.axios
        .post("/faturas/dados/apagar", this.dados_faturas)
        .then(() => {

          this.deleteDadosDialog = false;
          this.dados_faturas = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Medidor apagado com sucesso!",
            life: 3000,
          });

          this.listarFaturas();


        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

    apagarDadosFiscais() {
      this.axios
        .post("/faturas/dados-fiscais/apagar", this.dados_fiscais)
        .then(() => {

          this.deleteDadosFiscaisDialog = false;
          this.dados_fiscais = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Dados Fiscais apagados com sucesso!",
            life: 3000,
          });

          this.listarFaturas();


        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

    apagarDadosTributos() {
      this.axios
        .post("/faturas/dados-tributos/apagar", this.dados_tributos)
        .then(() => {

          this.deleteDadosTributosDialog = false;
          this.dados_tributos = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Dados Tributos apagados com sucesso!",
            life: 3000,
          });

          this.listarFaturas();


        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });

    },

















    deleteSelectedProducts() {
      this.faturas = this.faturas.filter(
        (val) => !this.selectImplantacoes.includes(val)
      );

      let obj = this.selectImplantacoes;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/faturas/apagar", obj[key])
          .then(() => {
            this.deleteModalFatura = false;
            this.fatura = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "faturas apagadas com sucesso!",
              life: 3000,
            });
            this.listarFaturas();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteProductsDialog = false;
      this.selectImplantacoes = null;
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },


    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

  },
};


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>